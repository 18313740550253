import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import { StaticImage } from 'gatsby-plugin-image'

import Scrollspy from 'react-scrollspy'
import Scroll from '../../components/Scroll'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'
import Nav from '../../components/Nav'

const Dap = ({ data, location }) => {
  const [stickyNav, setStickyNav] = useState(false)
  const handleWaypointEnter = () => {
    setStickyNav(false)
  }
  const handleWaypointLeave = () => {
    setStickyNav(true)
  }

  const dap_main = '../../assets/images/dap/dap_top_img.png'
  const dap_sc1 = '../../assets/images/dap/dap_sc1.png'

  return (
    <Layout>
      <Helmet title="Dot Anime Player" />
      <HeaderGeneric />
      <Waypoint
        onEnter={handleWaypointEnter}
        onLeave={handleWaypointLeave}
      ></Waypoint>
      <Nav sticky={stickyNav} />

      <div id="main">
        <section id="top" className="main special">
          <header className="major">
              <h2>Dot Anime Player</h2>
          </header>
          <span className="image major aligncenter"><StaticImage src={dap_main} alt="" width="310" /></span>
        </section>
        <section id="index" className="main">
          <Scrollspy items={ ['download', 'content', 'sc'] } currentClassName="is-active" offset={-300}></Scrollspy>
          <ul>
          <li>
              <Scroll type="id" element="download">
                  <a href="#download">ダウンロード</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="content">
                <a href="#content">内容</a>
              </Scroll>
            </li>
            <li>
              <Scroll type="id" element="sc">
                <a href="#sc">スクリーンショット</a>
              </Scroll>
            </li>
          </ul>
        </section>
        <section id="download" className="main">
          <header className="major">
            <h2>ダウンロード</h2>
          </header>
          <p>下記サイトよりダウンロードできます</p>
          <ul className="actions">
            <li>
              <a href="https://cretia-studio.booth.pm/items/3956719" target="_blank" rel="noopener noreferrer" className="button special">BOOTH</a>
            </li>
            <li>
              <a href="https://www.vector.co.jp/soft/winnt/art/se445059.html" target="_blank" rel="noopener noreferrer" className="button">Vector</a>
            </li>
          </ul>
        </section>
        <section id="content" className="main">
          <header className="major">
            <h2>内容</h2>
          </header>
          <p>ゲーム制作などに必要なグラフィックアニメーションの確認をするソフトです。<br />
          キャラの歩行グラフィックの確認や、エフェクトアニメの確認などに役立ちます。</p>

          <h3>◆ 主な機能</h3>
          <ul>
            <li>BMP、PNG、JPG形式に対応</li>
            <li>チップサイズ、アニメ枚数、アニメスピード、ループ方法など細かく設定可能</li>
            <li>設定を保存できるプロファイル機能</li>
            <li>WEBブラウザ内の素材画像からのドラッグアンドドロップにも対応</li>
            <li>RPGツクール2000、XP、VX用プロファイルを標準添付</li>
          </ul>

          <hr />

          <table>
            <colgroup><col span="1" /></colgroup>
            <tbody>
              <tr><th>タイトル</th><td>Dot Anime Player -ドットアニメプレイヤー-</td></tr>
              <tr><th>プラットフォーム</th><td>Windows 10 / 8 / 7 / Vista</td></tr>
              <tr><th>ジャンル</th><td>グラフィック支援ツール</td></tr>
              <tr><th>提供形態</th><td>フリーウェア</td></tr>
            </tbody>
          </table>
        </section>
        <section id="sc" className="main">
          <header className="major">
            <h2>スクリーンショット</h2>
          </header>
          <StaticImage src={dap_sc1} alt="" />
        </section>
      </div>
    </Layout>
  )
}

export default Dap
